<script setup lang="ts">
import { objectEntries } from '@vueuse/core'
import type { SbGridColumnStoryblok } from '~/types/storyblok'
import { tm } from '~/utils/tm'

const { blok } = defineProps<{ blok: SbGridColumnStoryblok }>()

const justifyContentStyle = computed(() => {
  if (!blok.horizontalAlign || blok.horizontalAlign === 'none') {
    return ''
  }

  return String(blok.horizontalAlign)
})

const gridColumnClass = computed(() => {
  const cols = Number(blok.cols)
  if (!cols) {
    return ''
  }

  switch (cols) {
    case 1:
      return 'lg:col-span-1'

    case 2:
      return 'lg:col-span-2'

    case 3:
      return 'lg:col-span-3'

    case 4:
      return 'lg:col-span-4'

    case 5:
      return 'lg:col-span-5'

    case 6:
      return 'lg:col-span-6'

    case 7:
      return 'lg:col-span-7'

    case 8:
      return 'lg:col-span-8'

    case 9:
      return 'lg:col-span-9'

    case 10:
      return 'lg:col-span-10'

    case 11:
      return 'lg:col-span-11'

    case 12:
      return 'lg:col-span-12'

    default:
      return ''
  }
})

const responsiveClassNames = computed(() => {
  if (!blok.devices) {
    return ''
  }

  const devices = {
    mobile: { shownClassNames: 'flex', hiddenClassNames: 'hidden' },
    tablet: { shownClassNames: 'sm:flex', hiddenClassNames: 'sm:hidden' },
    desktop: { shownClassNames: 'lg:flex', hiddenClassNames: 'lg:hidden' },
    largeDesktop: { shownClassNames: '2xl:flex', hiddenClassNames: '2xl:hidden' },
  }

  return objectEntries(devices).map(([name, { shownClassNames, hiddenClassNames }]) => {
    const isShown = blok.devices!.includes(name)
    return isShown ? shownClassNames : hiddenClassNames
  }).join(' ')
})
</script>

<template>
  <div
    v-editable="blok"
    class="flex grow flex-col"
    :class="tm(
      gridColumnClass,
      gridColumnClass && 'col-span-1',
      justifyContentStyle && 'flex w-full',
      responsiveClassNames,
    )"
    :style="{
      alignItems: justifyContentStyle,
    }"
  >
    <StoryblokComponent
      v-for="bodyBlok in blok.body"
      :key="bodyBlok._uid"
      :blok="bodyBlok"
    />
  </div>
</template>
